.filter-type {
  font-size: 1.2rem;
  .ant-divider-horizontal {
    margin: 5px 0 8px 0;
  }
}

.filter-title {
  font-size: 1.5rem;
  font-weight: 500;
}