
.iconGroup {
  span.anticon {
    margin-left: 16px;
    color: @text-color-secondary;
    cursor: pointer;
    transition: color 0.32s;
    &:hover {
      color: @text-color;
    }
  }
}

.rankingList {
  margin: 25px 0 0;
  padding: 0;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    margin-top: 16px;
    zoom: 1;
    &::before,
    &::after {
      display: table;
      content: ' ';
    }
    &::after {
      clear: both;
      height: 0;
      font-size: 0;
      visibility: hidden;
    }
    span {
      color: @text-color;
      font-size: 14px;
      line-height: 22px;
    }
    .rankingItemNumber {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: 1.5px;
      margin-right: 16px;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      background-color: @tag-default-bg;
      border-radius: 20px;
      &.active {
        color: #fff;
        background-color: #314659;
      }
    }
    .rankingItemTitle {
      flex: 1;
      margin-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.salesExtra {
  display: inline-block;
  margin-right: 24px;
  a {
    margin-left: 24px;
    color: @text-color;
    &:hover {
      color: @primary-color;
    }
    &.currentDate {
      color: @primary-color;
    }
  }
}

.salesCard {
  .salesBar {
    padding: 0 0 32px 32px;
  }
  .salesRank {
    padding: 0 32px 32px 72px;
  }
  :global {
    .ant-tabs-bar,
    .ant-tabs-nav-wrap {
      padding-left: 16px;
      .ant-tabs-nav .ant-tabs-tab {
        padding-top: 16px;
        padding-bottom: 14px;
        line-height: 24px;
      }
    }
    .ant-tabs-extra-content {
      padding-right: 24px;
      line-height: 55px;
    }
    .ant-card-head {
      position: relative;
    }
    .ant-card-head-title {
      align-items: normal;
    }
  }
}

.salesCardExtra {
  height: inherit;
}

.salesTypeRadio {
  position: absolute;
  right: 54px;
  bottom: 12px;
}

.offlineCard {
  :global {
    .ant-tabs-ink-bar {
      bottom: auto;
    }
    .ant-tabs-bar {
      border-bottom: none;
    }
    .ant-tabs-nav-container-scrolling {
      padding-right: 40px;
      padding-left: 40px;
    }
    .ant-tabs-tab-prev-icon::before {
      position: relative;
      left: 6px;
    }
    .ant-tabs-tab-next-icon::before {
      position: relative;
      right: 6px;
    }
    .ant-tabs-tab-active h4 {
      color: @primary-color;
    }
  }
}

.trendText {
  margin-left: 8px;
  color: @heading-color;
}

@media screen and (max-width: @screen-lg) {
  .salesExtra {
    display: none;
  }

  .rankingList {
    li {
      span:first-child {
        margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: @screen-md) {
  .rankingTitle {
    margin-top: 16px;
  }

  .salesCard .salesBar {
    padding: 16px;
  }
}

@media screen and (max-width: @screen-sm) {
  .salesExtraWrap {
    display: none;
  }

  .salesCard {
    :global {
      .ant-tabs-content {
        padding-top: 30px;
      }
    }
  }
}

.stationCard {
  cursor: pointer !important;
  background-color: transparent !important;

  .ant-card-body{
    display: none !important;
  }
  .ant-card-head {
    padding: 0 !important;
    height: 32px !important;
  }
  .ant-card-head-title {
    padding: 0 !important;
    border-radius: 16px 16px 0px 0px !important;
  }
  .ant-card-cover{
    margin-left: 0px !important;
    margin-right: 0px !important;
    img{
      border-radius: 0px 0px 16px 16px !important;
    }
  }

}

.stationTextCard {
  border: none !important;
  cursor: pointer !important;
  .ant-card-head {
    padding: 0 !important;
    height: 32px !important;
  }
  .ant-card-body {
    padding: 20px 8px;
  }
  .ant-card-head-title {
    padding: 0 !important;
  }
}

.cardTitle {
  text-align: center !important;
  h2 {
    width: 100%;
    // font-weight: bold !important;
    margin-bottom: 0px !important;
    white-space:nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  h3 {
    font-size: 17px !important;
    color: #323338 !important;
  }
}

.skp_header_rect {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  text-align: center;
  padding: 10px 0;
}

.skp_normal {
  background: #AFEEFF;
}

.skp_grey {
  background: #C3C3C5;
}

.skp_warning {
  background: #E9A600;
}

.skp_alert {
  background: #E03734;
}

.panel_left {
  background: #F5F5F5 !important;
  padding: 50px 40px !important;
  .panel_left_card_title{
    h1{
      font-weight: bold !important;
      font-size: 36px !important;
      margin-left: 5px !important;
    }
    h3{
      font-weight: bold !important;
      font-size: 14px !important;
      margin-left: 5px !important;
    }      
  }
}

.panel_right {
  padding: 20px !important;
  h1{
    font-weight: bold !important;
    font-size: 36px !important;
    margin-left: 5% !important;
  }
  span,p{
    font-size: 16px !important;
  }
  h2{
    font-weight: bold !important;
    font-size: 36px !important;
    span{
      font-size: 14px !important;
    }
  }
  .ant-col-6{
    line-height: 50px !important;
  }
}

@modal_height: 70vh;

.skp_modal {
  .ant-modal-body {
    padding: 0 !important;
    min-height: @modal_height !important;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
  }
  .ant-modal-content{
    background-color: transparent !important;
    box-shadow: none !important;
    .skp_row_modal_left_panel{
      background-color: white;
    }
    .ant-modal-close-x{
      font-size: 26px;
      color: #C3C3C5;
    }
    .panel_right{
      background-color: white;
      min-height: @modal_height !important;
    }
  }
}

.skp_row_modal {
  max-height: 100% !important;
  min-height: @modal_height !important;
}

.menu_row_modal {
  .ant-input-number-input {
    text-align: right;
  }
}
.noshow {
  display: none;
}

.skp_row_modal_left_panel{
  padding: 60px 40px !important;
  h1{
    font-size: 36px !important;
    font-weight: bold !important;
    padding-left: 5px !important;
  }
  h4{
    font-size: 14px !important;
    font-weight: bold !important;
    padding-left: 5px !important;
  }
  .ant-card-head-title{
    border-radius: 16px !important;
  }
  .ant-card-body{
    font-weight: normal !important;
    font-size: 16px !important;
  }
}

.skp_image_full_modal {
  min-width: 100% !important;
  min-height: @modal_height !important;
  max-width: 100% !important;
  max-height: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  background: #f0f2f5;
}

.skp_button_type_primary {
  // background: #7AAEEB !important;
  border-radius: 60px !important;
  border: none !important;
  width: 180px !important;
  height: 50px !important;
  span {
    color: #FFFFFF !important;
    margin-left: 10px !important;
    font-weight: bold !important;
    font-size: 22px !important;
    font-family: 'Montserrat', sans-serif !important;
  }
}

.skp_button_right_bottom {
  position: absolute !important;
  right: 0px !important;
  bottom: 0px !important;
}

.panel_grid_row {
  margin: 20px 0 !important;
}

.skp_rect {
  margin: auto;
  border-radius: 0px;
  width: 70%;
  height: 70%;
  text-align: center;
  padding: 8px 0;
  span {
    padding: auto;
    color: #FFFFFF;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    vertical-align: sub;
  }
}

.bottom_row {
  margin: 100px 0px 0px 0px;
  .middle_col {
    margin: 0px auto;
    float: left;
    flex: 0;
  }
}

.station_header_img {
  max-height: 30px;
}

.station-row {
  margin-top: 30px;
}
