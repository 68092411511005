@font-face {
  font-family: "Manrope";
  src: url("../../fonts/Manrope-ExtraLight.ttf") format("truetype");
  font-weight: @font-weight-extraLight;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../fonts/Manrope-Light.ttf") format("truetype");
  font-weight: @font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../fonts/Manrope-Regular.ttf") format("truetype");
  font-weight: @font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../fonts/Manrope-Medium.ttf") format("truetype");
  font-weight: @font-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../fonts/Manrope-SemiBold.ttf") format("truetype");
  font-weight: @font-weight-semiBold;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../fonts/Manrope-Bold.ttf") format("truetype");
  font-weight: @font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../fonts/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: @font-weight-extraBold;
  font-style: normal;
}