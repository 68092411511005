.hLine {
  margin: 1.6rem 0px;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  align-items: center;
  gap: 1.5rem;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.8rem;
}

.hLine::before, .hLine::after {
  content: "";
  display: inline-block;
  border-top: 0.1rem solid rgb(227, 229, 231);
  -webkit-box-flex: 1;
  flex-grow: 1;
}