.card-container p {
    margin: 0;
}

.card-container>.ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
}

.card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
    padding: 40px 16px 16px 16px;
    background: #fff;
}

.card-container>.ant-tabs-card>.ant-tabs-nav::before {
    display: none;
}

.card-container>.ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

.card-container>.ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
}

#components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
}

[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
    background: #141414;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-tab-active {
    background: #141414;
    border-color: #141414;
}

.card-container .ant-btn {
    margin-right: 8px;
}

.card-container .ant-form:not(.ant-form-inline):not(.ant-form-vertical) {
    max-width: 600px;
}

.card-container .tab-item-header {
    padding: 30px 40px;
}