.iconDashBg {
  padding: 12px;
  max-height: 48px;
   border-radius: 50%
}
.leftoverTitle {
  display:inline-block;
  width:4.5rem;
}
.leftoverLb {
  display:inline-block;
  width:3.8rem;
  text-align:right;
}
.leftoverRatio {
  display:inline-block;
  margin-left:10px;
  width:2rem;
  text-align:right;
}
.portionsTitle {
  display:inline-block;
  width:5.3rem;
}